.App {
  text-align: center;
}

body{
background-color: #f6facf;
}

.name-brand{
  font-family:'Lilita One';
  font-size: x-large;
}

.custom-link{
  text-decoration: none;
  color: black;
  font-size: larger;
  font-weight: 400;
  border-bottom: 3px dashed #000000;
}

.custom-link-sm{
  text-decoration: none;
  color: black;
  font-size:small;
  font-weight: 300;
  border-bottom: 3px dashed #424242;
}

.custom-link:hover{
  background-color: #fcb902;
  font-weight: bold;
}

.custom-link-sm:hover{
  background-color: #fcb902;
  font-weight: bold;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.custom-card {
  background: none; /* Remove background color */
  border: none; /*Remove border */
  box-shadow: none; /* Remove shadow if present */
  margin: 0; /* Remove default margin */
}

.cover-text {
  font-size: 25px; /* Default font size for extra small screens */
  color: #333;
  line-height: 1.5;
  text-align: left;
  font-weight: bold;
  white-space: pre-line;
}

/* Media query for small screens */
@media (min-width: 576px) and (max-width: 767px) {
  .cover-text{
    font-size: 25px; /* Adjust font size for small screens */
    text-align: left;
  }
}

/* Media query for medium screens */
@media (min-width: 768px) and (max-width: 991px) {
  .cover-text {
    font-size: 35px; /* Adjust font size for medium screens */
    text-align: left;
  }
}

/* Media query for large screens */
@media (min-width: 992px) {
  .cover-text {
    font-size: 50px; /* Adjust font size for large screens */
    text-align: left;
  }
}

.no-background-border {
  background: none;
  border: none;
  box-shadow: none;
}


.center-text {
  text-align: center !important;
}

.underline {
    text-decoration: underline;
  }

  .modalImgTxt {
    text-align: center;
    font-size: larger;
    font-weight: bold;
  }


  /* Cover.css */

@keyframes rockingAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust the distance of rocking */
  }
}

.rocking-caret {
  animation: rockingAnimation 2.5s infinite; /* Adjust the animation duration as needed */
  color: #ffa600;
}

.social-link {
  display: inline-block;
  transition: transform 0.3s, font-size 0.3s, color 0.3s;
}

.social-link:hover {
  transform: scale(1.5); /* Increase size on hover */
  color: #FF5733; /* Change color on hover */
}

/* Define the animation */
@keyframes nameAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Apply the animation on hover */
.disappearing-name:hover {
  animation: nameAnimation 1s ease-in-out;
}

.custom-project-card {
  border: 5px solid transparent; 
  transition: border-color 0.3s; 
  background-color: white;
  border-radius: 3%;
}

.custom-project-card:hover {
  border-color: rgb(101, 134, 223); 
}



/* Center the form */
form {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

/* Style form labels */
label {
  color: rgb(0, 0, 0);
  display: block;
  margin-bottom: 10px;
}

/* Style form inputs and textarea */
input[type="text"],
input[type="email"],
input[type="phone"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 20px;
}

textarea {
  min-height: 150px;
}

/* Style the submit button */
input[type="submit"] {
  background: #0275d8;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 50px;
}

.navToggler{
  outline: none;
}